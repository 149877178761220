<p-dialog [(visible)]="migrando" header="Estamos preparando su cuenta en la nueva plataforma..." [modal]="true" [draggable]="false" [closable]="false" [resizable]="false">
  <ng-container *ngIf="!migrado">
    <img src="assets/sync.svg"/>
    <p-progressBar [mode]="!total ? 'indeterminate' : 'determinate'" [value]="round(current*100/total)"></p-progressBar>
  </ng-container>
  <ng-container *ngIf="migrado">
    <img src="assets/icons/check.svg" style="height: 20vh;"/>
    <h3 style="text-align: center;">Su usuario fue sincronizado correctamente.</h3>
  </ng-container>
  <footer>
    <p>Puede esperar a que termine, o puede continuar con los datos que ya fueron migrados, (los datos se van a seguir migrando aunque cierre la página)</p>
    <button class="primary" (click)="onClose()">Cerrar</button>
  </footer>
</p-dialog>

<p-dialog [(visible)]="migrationError" header="Ocurrió un error..." [modal]="true" [draggable]="false" [closable]="false" [resizable]="false">
  <img src="assets/oops.svg" />
  <footer>
    <p>Ocurrió un error al migrar los datos de su usuario, por favor contacte un administrador.</p>
    <!-- <button class="primary" (click)="onClose()">Cerrar</button> -->
  </footer>
</p-dialog>
