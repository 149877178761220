import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { inject } from '@angular/core';

export const createFieldGuard: CanActivateFn = (route, state) => {
  let userRoles: string[] = [];
  const authSrv = inject(AuthService);
  const router = inject(Router);
  authSrv.setUserRoles();
  userRoles = authSrv.getUserRoles();
  if (!userRoles.includes('ROLE_CREATE_FIELD')) {
    router.navigateByUrl('/home');
    return false;
  }
  return true;
};
