export function makeRandomId(length: number){
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  for (let i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

export const utils = {
  getClientProducerSelected() {
    return localStorage.getItem('producerClient') ? JSON.parse(localStorage.getItem('producerClient')!) : undefined;
  }
  
}

function hexToRgb(color: string) {
  const bigint = parseInt(color.substring(1), 16);
  return {
    r: (bigint >> 16) & 255,
    g: (bigint >> 8) & 255,
    b: bigint & 255
  };
}

function randomColorHexaGet() {
  return '#' + Math.floor(Math.random() * 16777215).toString(16);
}

export function randomColorHexaWithoutGrays(): string {
  const color = randomColorHexaGet();
  if (color.length != 7) {
    return randomColorHexaWithoutGrays();
  }
  const colorRgb = hexToRgb(color);
  // check enough difference between r, g, b to prevent gray colors
  if (Math.abs(colorRgb.r - colorRgb.g) < 20 && Math.abs(colorRgb.r - colorRgb.b) < 20 && Math.abs(colorRgb.g - colorRgb.b) < 20) {
    return randomColorHexaWithoutGrays();
  }
  // prevent black and white
  if (colorRgb.r < 20 && colorRgb.g < 20 && colorRgb.b < 20) {
    return randomColorHexaWithoutGrays();
  }
  if (colorRgb.r > 200 && colorRgb.g > 200 && colorRgb.b > 200) {
    return randomColorHexaWithoutGrays();
  }
  return color;
}

export function checkMigratedEnvironmentNameIsValidForCampaign(environmentName: string, campaignYear: number): boolean {
  // Obtener la expresión regular para el formato del nombre del entorno
  const environmentNameRegex = /^(\w+)-\((\d+)\)$/;
  // Verificar si el nombre del entorno cumple con el formato
  if (!environmentNameRegex.test(environmentName)) {
      return true; // Retorna true si no cumple con el formato
  }
  // Extraer el año del nombre del entorno
  const extractedYear = parseInt(environmentName!.match(environmentNameRegex)?.[2]! ?? 0, 10);
  // Verificar si el año extraído coincide con el año de la campaña
  return extractedYear === campaignYear;
}

export function getMigratedEnvironmentNameIsValidForCampaign(environmentName: string) {
  // Obtener la expresión regular para el formato del nombre del entorno
  const environmentNameRegex = /^(\w+)-\((\d+)\)$/;
  // Verificar si el nombre del entorno cumple con el formato
  if (!environmentNameRegex.test(environmentName)) {
      return undefined; // Retorna true si no cumple con el formato
  }
  // Extraer el año del nombre del entorno
  const extractedYear = parseInt(environmentName!.match(environmentNameRegex)?.[2]! ?? 0, 10);
  // Verificar si el año extraído coincide con el año de la campaña
  return extractedYear == 0 ? undefined: extractedYear;
}







