import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PremiumService {

  fieldsCount = 0;

  readonly onPremium = new EventEmitter<string>();
  constructor(private httpC: HttpClient) { }

  isPremiumUser(tag: string) {
    if (localStorage.getItem('user')) {
      const user = JSON.parse(localStorage.getItem('user')!)
      if(!user.pay) {
        this.onPremium.emit(tag);
        return false;
      }
    }
    return true;
  }

  openModal(tag: string) {
    this.isPremiumUser(tag);
  }

  requestProSubscripiton() {
    return this.httpC.post(`${environment.api}user/request-subscription`, {});
  }

  getStatus() {
    return this.httpC.get<{maintenance: boolean}>(`${environment.api}status`, {
      headers: {
        'X-ANONYMOUS': 'YES'
      }
    });
  }


  //funciones para informar en la pantalla de map-studio y validar que no pueda crear mas de un campo si no es usuario premium
  setFieldsCount(fieldsCount: number) {
    this.fieldsCount = fieldsCount
  }

  getFieldsCount() {
    return this.fieldsCount;
  }

}
