import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {

  private breadcrumbsArray: { label: string; url: string }[] = [];
  private breadcrumbs: BehaviorSubject<{ label: string; url: string }[]> = new BehaviorSubject(this.breadcrumbsArray);

  constructor() {}

  getBreadcrumbs() {
    return this.breadcrumbs.asObservable();
  }

  addBreadcrumb(label: string, url: string): void {
    this.breadcrumbsArray.push({ label, url });
    this.breadcrumbs.next(this.breadcrumbsArray);
  }

  removeLastBreadcrumb(): void {
    this.breadcrumbsArray = [];
    this.breadcrumbs.next(this.breadcrumbsArray);
  }
}
