<a href (click)="pDialog=true;$event.preventDefault()">Notas de la versiön {{currentVersion}}</a>
<p-dialog class="userModal" header="Historial de cambios de las últimas versiones" [(visible)]="pDialog" [modal]="false" [draggable]="true" [closable]="true" [resizable]="false">
  <article>
    <h3>Cambios en la versión {{currentVersion}}</h3>
    <ul>
      <li>
        <h3>Backoffice</h3>
        <ol>
          <li>Permiso para que el usuario pueda importar/exportar excels de calculo.</li>
          <li>Opcion para cambiar contraseña del usuario.</li>
        </ol>
        <h3>CFT</h3>
        <ol>
          <li>Mostrar estado de mantenimiento si se cambio el estado previo a ejecutar el cálculo.</li>
        </ol>
        <h3>Plataform</h3>
        <ol>
          <li>Contacto a soporte con un formulario de consulta.</li>
        </ol>
      </li>
    </ul>
    <h3>Cambios en la versión 0.101</h3>
    <ul>
      <li>
        <h3>Certificados y reportes</h3>
        <ol>
          <li>Se agregó card de acceso a certificados en pantalla de Carbon Studio.</li>
        </ol>
        <h3>General</h3>
        <ol>
          <li>Se agregó buscador en modal de clientes - productores.</li>
          <li>Se ajustó el ordenamiento de la lista de clientes - productos por nombre de clientes.</li>
          <li>Se agregó pantalla de mantenimiento.</li>
          <li>Se ajustó la validaciones decoordenadas para la importación de archivos KML/KMZ.</li>
          <li>Se agregaron guards para validar los roles al ingreso de ciertas pantallas.</li>
          <li>Se agregaron modales de aviso ante la falta de cierto rol para ejecutar acciones o ingresar a pantallas.</li>
          <li>Se agregaron los logins cruzados entre plataformas.</li>
        </ol>
        <h3>Calculadora</h3>
        <ol>
          <li>Mapeo de rodeos migrados en reportes.</li>
          <li>Ajustes en porcentaje de participación de emisiones en reportes.</li>
          <li>Validaciones de inputs de redeos para terneros y terneras.</li>
          <li>Ajustes visuales en configuración de suelos al crear cálculo.</li>
          <li>Se agregó la funcionalidad de importación y exportación de Excel en la biblioteca de cálculos.</li>
        </ol>
      </li>
    </ul>
    <h3>Cambios en la versión 0.100</h3>
    <ul>
      <li>
        <h3>Certificados y reportes</h3>
        <ol>
          <li>Se estilaron los formatos de los certificados.</li>
          <li>Se ajustaron los botones de exportación según pedido de Sebastián.</li>
        </ol>
        <h3>Calculadora</h3>
        <ol>
          <li>Se corrigió texto "forrajero".</li>
          <li>Se corrigió la carga incial de unidades de fertilizantes de datos migrados.</li>
          <li>Se adaptaron datos y gráficos en rerportes por cultivos.</li>
          <li>Se separó la configuración de suelos de los datos de cultivos.</li>
          <li>Se ajustó el mapeo de datos de cálculos ganaderos.</li>
          <li>Se corrigieron validaciones de categoría de rodeos.</li>
          <li>Implementación de palabra reservada "HC" en creación de ambientes desde Map Studio.</li>
          <li>Corrección en sumatoria de rodeos.</li>
          <li>Corrección en sumatoria de lotes y totalizador de hectáreas por campo.</li>
          <li>Ajustes en importación de archivos KML/KMZ.</li>
        </ol>
        <h3>General</h3>
        <ol>
          <li>Traducción de filtros de tablas.</li>
          <li>Se agregaron máscaras de formato a los inputs de número de teléfono y CUIT en modal de registro.</li>
        </ol>
        <h3>Analítica</h3>
        <ol>
          <li>Se agregaron botones para seleccionar entre agricultura, ganadería y la comparativa con Retaa.</li>
          <li>Ajustar permisos para mostrar o no botones.</li>
        </ol>
        <h3>Backoffice</h3>
        <ol>
          <li>Gestión de nuevos permisos para calculadora, simulador y comparartiva Retaa.</li>
          <li>Optimizaciones en carga gestión de campos - campañas.</li>
          <li>Pantalla de configuración para poner CFT en mantenimiento.</li>
          <li>Mapeo de tickets de soporte faltantes.</li>
          <li>Textos informativos para desplegables sin datos.</li>
        </ol>
      </li>
    </ul>
    <h3>Cambios en la versión 0.99</h3>
    <ul>
      <li>
        <h3>Certificados y reportes</h3>
        <ol>
          <li>Se agregó la nueva pantalla de certificados y reportes</li>
          <li>Se corrigieron los reportes a nivel cálculo</li>
        </ol>
        <h3>General</h3>
        <ol>
          <li>Se corrigieron bugs en el SSO y se incorporó la posibilidad de seleccionar destino cuando hay mas de un rol habilitado</li>
        </ol>
      </li>
    </ul>
    <h3>Cambios en la versión 0.98</h3>
    <ul>
      <li>
        <h3>Calculadora PRO</h3>
        <ol>
          <li>Se corrige un bug por el cual al editar un rodeo y cancelar la edicion se aplicaban los cambios de igual forma</li>
          <li>Se agrega validación para requerir completar el valor en fuel y en food (ganadería)</li>
          <li>Si calcular ganadería arrojaba error, el mismo no se mostraba dando la sensación que "no hacía nada"</li>
          <li>Ganadería te permitía calcular sin ningún rodeo</li>
          <li>Al eliminar una card de ganadería arrojaba 2 carteles de confirmación</li>
          <li>Ya no se permiten agregar filas vacias en estiercol/alimentación de un rodeo</li>
          <li>En agricultura ya no se permiten cambiar las cards mientras está calculando</li>
          <li>Al cambiar una card con un calculo por una nueva se deshabilita el botón de ver reporte</li>
        </ol>
      </li>
      <li>
        <h3>General</h3>
        <ol>
          <li>Se corrige el diseño del formulario de registro para que las opciones tengan un tamaño fijo</li>
          <li>Los campos de rol y actividad se vuelven obligatorios</li>
          <li>Se corrigieron numeros gigantes en valores del suelo (solo 4 decimales)</li>
          <li>Se corrigio el proceso de sincronizacion de usuario</li>
          <li>Se corrigieron detalles visuales en recordar-contraseña, para que aparezca a que mail se envió</li>
          <li>Se corrigieron carteles de errores en el login.</li>
        </ol>
      </li>
    </ul>
    <h3>Cambios en la versión 0.97</h3>
    <ul>
      <li>
        <h3>Calculadora Pro.</h3>
        <ol>
          <li>Se corrige un error de diseño en el reporte para los cultivos de segunda</li>
        </ol>
      </li>
      <li>
        <h3>Calculadora CFT.</h3>
        <ol>
          <li>Agregamos botón de guardar en configuración de clima</li>
          <li>Agregamos todas las validaciones para cada sección</li>
          <li>Se armó los tildes de configurado correctamente/pendiente de configurar en cada fila</li>
          <li>Agregamos validación para que ningún % puede ser mayor a 100</li>
          <li>Si editabas un rodeo, no te daba la opcion de agregar nuevos tipos, solo mostraba los que se habian creado en un principio.</li>
        </ol>
      </li>
    </ul>
    <h3>Cambios en la versión 0.96</h3>
    <ul>
      <li>
        <h3>Calculadora Pro.</h3>
        <ol>
          <li>Corrección visual en las cards de cultivos que en dispositivos de pantallas pequeñas se desbordaba de su contenedor.</li>
          <li>Corrección visual que en los modales de edición de cultivo para la cuenta de insumos/semillas/fertilizantes se colapsaba el diseño.</li>
          <li>Bloqueo de inputs numericos para que no soporten valores negativos.</li>
          <li>Se bloquea la posibilidad de crear cards sin nombres.</li>
          <li>Se corrige el cálculo de área sembrada / cosechada de la previsualización de las cards.</li>
          <li>Se agrega la posibilidad de poder borrar cards.</li>
          <li>Se corrige el cálculo del balance en suelos por tonelada, dividiendo el valor por el yield_fresh (rendimiento) y si hay ganadería también por el porcentaje de meses de ocupación.</li>
          <li>Al editar se puede visualizar el reporte individual de cada cultivo.</li>
          <li>Se corrige el formato de numeros en el reporte para que solo tengan 4 decimales como máximo.</li>
          <li>Se agrega el CUIT al reporte.</li>
          <li>El botón de calcular ahora arroja un cartel indicando qué debiera hacer el usuario cuando no tienes cultivos configurados.</li>
          <li>Se corrige un bug que arrojaba NaN en los resultados del reporte, cuando un ambiente no tenia cultivo.</li>
          <li>Se corrige un bug que no dejaba calcular en algunos casos arrojando un error 500.</li>
          <li>Se ajustó visualmente la lista de cultivos en el reporte para que queden separados.</li>
          <li>Se corrigió que no se pudiera continuar sin actividad seleccionada cuando editabas.</li>
          <li>Al tratar de agregar un elemento clickeando en laboreos, aparece un toast informando qué debe hacer el usuario.</li>
          <li>Si se creaba una card de RetAA, traia valores para pasadas y para hectareas, lo que deshabilitaba los dos campos para poder completar solo uno, ahora se queda con el valor de pasadas unicamente si lo tiene.</li>
        </ol>
      </li>
    </ul>
  </article>
</p-dialog>
