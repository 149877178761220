<p-dialog [(visible)]="premiumModal" [modal]="true" [draggable]="false" [closable]="false" [resizable]="false">
  <main *ngIf="!contacted">
    <img src="assets/dash-mock.png" />
    <h3>Conviertete en Premium</h3>
    <p>
      Accede a los beneficios de esta herramienta y a otras de gran valor para tu negocio.
    </p>
    <div class="buttons">
      <button (click)="close()">Cerrar</button>
      <button *ngIf="!contacted"
        class="secondary buttonLoader"(click)="subscribe()">
        <span *ngIf="!loading">Suscribirme</span>
        <div *ngIf="loading" class="loaderBars"></div>
      </button>
    </div>
    <!-- <button (click)="subscribe()" class="secondary">Suscribirme</button> -->
  </main>
  <main *ngIf="contacted">
    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="20" viewBox="0 0 640 512"><path d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM625 177L497 305c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L591 143c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/></svg>
    <h3>Te contactaremos!</h3>
    <p>
      Te contactaremos a la brevedad para darte más información sobre los beneficios de esta herramienta.
    </p>
    <button (click)="close()" style="display: block;margin: 0 auto;">Cerrar</button>
  </main>
</p-dialog>
