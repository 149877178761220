import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, switchMap, filter, take } from 'rxjs/operators';
import { AuthService } from '../auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Agrega el token a la solicitud si está disponible
    const token = '' //this.authService.getToken();
    if (token) {
      request = this.addToken(request, token);
    }

    return next.handle(request).pipe(
      catchError(error => {
        return throwError(error);
        // if (error instanceof HttpErrorResponse && error.status === 401) {
        //   return this.handle401Error(request, next);
        // } else {
        //   return throwError(error);
        // }
      })
    );
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  // private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
  //   if (!this.isRefreshing) {
  //     this.isRefreshing = true;
  //     this.refreshTokenSubject.next(null);

  //     return this.authService.refreshAccessToken().pipe(
  //       switchMap((token: any) => {
  //         this.isRefreshing = false;
  //         this.refreshTokenSubject.next(token);
  //         return next.handle(this.addToken(request, token.token));
  //       }),
  //       catchError(error => {
  //         this.isRefreshing = false;
  //         // Manejo adicional de errores de renovación de token (p. ej., redireccionar al inicio de sesión)
  //         return throwError(error);
  //       })
  //     );
  //   } else {
  //     return this.refreshTokenSubject.pipe(
  //       filter(token => token !== null),
  //       take(1),
  //       switchMap(() => {
  //         // return next.handle(this.addToken(request, this.authService.getToken()));
  //         return next.handle(this.addToken(request, ''));
  //       })
  //     );
  //   }
  // }
}

//TODO: implementar el refresh token
