import { Injectable } from '@angular/core';
import { FieldSimulationDtoOut, LotSimulationDtoOut, SimulationsScenariosOut } from '../pages/library/fields-table/fields-table-internal.dto';
import { HttpClient } from '@angular/common/http';
import { Campaigns, DefaultResponse } from './domains/calculator.domain';
import { environment } from 'src/environments/environment';
import { CreateScenarioIn, CreateScenarioOut, SimulationCardCampaign, SimulationCardInDto, SimulationCardOutDto, SimulationGetEmissionsDone, SimulationGetEmissionsWait, SimulationGetLotList, SimulationLotEmission, SimulationYearConfig } from './domains/simulator.domain';
import { utils } from '../utils/utils';
import { BehaviorSubject, Subject, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SimulatorService {

  simulations = new BehaviorSubject<SimulationsScenariosOut[]>([]);
  reloadFields = new Subject<boolean>();

  constructor(private httpC: HttpClient) { }

  public getLots() {
    return this.httpC.get<DefaultResponse<LotSimulationDtoOut[]>>(`${environment.api}simulation/lots?idClient=${this.getClientProducerSelected().clientID}&idProducer=${this.getClientProducerSelected().producerID}`)
  }

  public getLotsByField(id: string) {
    return this.httpC.get<DefaultResponse<LotSimulationDtoOut[]>>(`${environment.api}simulation/fields/${id}/lots?idClient=${this.getClientProducerSelected().clientID}&idProducer=${this.getClientProducerSelected().producerID}`);
  }

  public getSimulationGroups(id: string) {
    return this.httpC.get<DefaultResponse<CreateScenarioOut>>(`${environment.api}simulation/${id}?idClient=${this.getClientProducerSelected().clientID}&idProducer=${this.getClientProducerSelected().producerID}`);
  }

  public makeNewSimulationGroups(idField: string, body: CreateScenarioIn) {
    return this.httpC.post<DefaultResponse<CreateScenarioOut>>(`${environment.api}simulation/${idField}?idClient=${this.getClientProducerSelected().clientID}&idProducer=${this.getClientProducerSelected().producerID}`, body);
  }

  public getCampaigns() {
    return this.httpC.get<DefaultResponse<{ campaigns: Campaigns[], lastCampaign: Campaigns }>>(`${environment.api}simulation/campaigns?idClient=${this.getClientProducerSelected().clientID}&idProducer=${this.getClientProducerSelected().producerID}`);
  }

  public getSimYearConfigInfo(id: number) {
    return this.httpC.get<DefaultResponse<{yearStart: number, yearsToSimulate: number, idField: number, fieldName: string, idSimulation: number}>>(`${environment.api}simulation/year/config/${id}?idClient=${this.getClientProducerSelected().clientID}&idProducer=${this.getClientProducerSelected().producerID}`);
  }

  public getCards() {
    return this.httpC.get<DefaultResponse<{ models: SimulationCardOutDto[] }>>(`${environment.api}simulation/cards?idClient=${this.getClientProducerSelected().clientID}&idProducer=${this.getClientProducerSelected().producerID}&limit=200`);
  }

  public createSimulation(idlot: number, name: string, year: number, yearsToSimulate: number) {
    return this.httpC.post<DefaultResponse<SimulationsScenariosOut>>(`${environment.api}simulation/${idlot}?idClient=${this.getClientProducerSelected().clientID}&idProducer=${this.getClientProducerSelected().producerID}`, {
      name,
      year,
      yearsToSimulate
    });
  }


  public createCard(idLot: number, emission: boolean = false, modelDataIn: SimulationCardInDto) {
    return this.httpC.post<DefaultResponse<SimulationCardOutDto>>(`${environment.api}simulation/card/${idLot}?emission=${emission}&idClient=${this.getClientProducerSelected().clientID}&idProducer=${this.getClientProducerSelected().producerID}`,modelDataIn);
  }

  public getSimulation(idSimulation: number) {
    return this.httpC.get<SimulationCardCampaign[]>(`${environment.api}simulation/calculate/${idSimulation}?idClient=${this.getClientProducerSelected().clientID}&idProducer=${this.getClientProducerSelected().producerID}`);
  }

  public updateSimulation(idSim: number, state:SimulationYearConfig[]) {
    return this.httpC.put<DefaultResponse<SimulationsScenariosOut>>(`${environment.api}simulation/calculate/${idSim}?idClient=${this.getClientProducerSelected().clientID}&idProducer=${this.getClientProducerSelected().producerID}`,{
      simulationYear: state
    });
  }

  public generateSimulation(idSim: number, state:SimulationYearConfig[]) {
    return this.httpC.post<DefaultResponse<SimulationsScenariosOut>>(`${environment.api}simulation/calculate/${idSim}?idClient=${this.getClientProducerSelected().clientID}&idProducer=${this.getClientProducerSelected().producerID}`,{
      simulationYear: state
    });
  }

  public  getSimulationEmission(idSim: number) {
    return this.httpC.get<DefaultResponse<SimulationGetEmissionsWait | SimulationGetEmissionsDone[]>>(`${environment.api}simulation/calculate/${idSim}/emission?idClient=${this.getClientProducerSelected().clientID}&idProducer=${this.getClientProducerSelected().producerID}`);
  }

  public  getSimulationEmissionLotList(idSim: number) {
    return this.httpC.get<DefaultResponse<SimulationGetLotList>>(`${environment.api}simulation/lots/${idSim}?idClient=${this.getClientProducerSelected().clientID}&idProducer=${this.getClientProducerSelected().producerID}`);
  }

  public  getSimulationEmissionFromLot(idSim: number, idLot: number) {
    return this.httpC.get<DefaultResponse<SimulationLotEmission>>(`${environment.api}simulation/report/${idSim}/${idLot}?idClient=${this.getClientProducerSelected().clientID}&idProducer=${this.getClientProducerSelected().producerID}`);
  }

  getFields() {
    return this.httpC.get<DefaultResponse<FieldSimulationDtoOut[]>>(`${environment.api}simulation/fields?idClient=${this.getClientProducerSelected().clientID}&idProducer=${this.getClientProducerSelected().producerID}`)
    .pipe(
      tap((response: DefaultResponse<FieldSimulationDtoOut[]>) => {
        const data = response.payload.flatMap(elem => elem.simulations)
        this.simulations.next(data)
      })
    );
  }


  getClientProducerSelected() {
    return utils.getClientProducerSelected();
  }

  scenerys: any[] = [];
  setScenerysArray(scenerys: any[]) {
    scenerys.forEach((scenery: any) => {
      this.scenerys.push(scenery);
    })
  }

  getScenerysArray() {
    return this.scenerys;
  }

  exportSimulations() {
    return this.httpC.get<DefaultResponse<any>>(`${environment.api}user/export/simulations?idProducer=${this.getClientProducerSelected().producerID}&idClient=${this.getClientProducerSelected().clientID}`, {
      responseType: 'arraybuffer' as 'json',
    });
  }


}




