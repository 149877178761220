import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, catchError, finalize, throwError } from 'rxjs';
import { LoadingService } from '../loading.service';
import { AuthService } from '../auth.service';

@Injectable()
export class ApiCatchsInterceptor implements HttpInterceptor {

  constructor(private readonly loadingService: LoadingService, private readonly authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Muestra el loading antes de enviar la solicitud
    //this.loadingService.show(); // Mostrar el indicador de carga antes de enviar la solicitud

    // if(!request.headers.has('X-AUTHENTICATED')) {
    //   return this.byPass(request, next);
    // }
    if(request.headers.has('X-ANONYMOUS')) {
      return this.byPass(request, next);
    }

    if(!this.authService.isLogged()) {
      // Redirect to SSO
      this.authService.goToSSO();
      return throwError('Not logged');
    }

    // Verificar si la solicitud ya tiene el encabezado de autorización
    if (!request.headers.has('Authorization')) {
      // Si no tiene el encabezado de autorización, agregarlo
      const authReq = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${this.authService.getJWT()}`)
      });

      return next.handle(authReq).pipe(
        catchError((error: HttpErrorResponse) => {
          // Manejo de errores
          // Aquí puedes mostrar mensajes de error, redirigir, etc.
          // Ejemplo: this.errorService.handleError(error);

          return throwError(error);
        }),
        finalize(() => {
          // Oculta el loading después de la respuesta (sea éxito o error)
          //this.loadingService.hide(); // Ocultar el indicador de carga después de la respuesta (éxito o error)
        })
      );
    } else {
      return this.byPass(request, next);
    }
  }

  byPass(request: HttpRequest<any>, next: HttpHandler) {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        // Manejo de errores
        // Aquí puedes mostrar mensajes de error, redirigir, etc.
        // Ejemplo: this.errorService.handleError(error);

        return throwError(error);
      }),
      finalize(() => {
        // Oculta el loading después de la respuesta (sea éxito o error)
        //this.loadingService.hide(); // Ocultar el indicador de carga después de la respuesta (éxito o error)
      })
    );
  }
}
