import { Injectable } from '@angular/core';
import { PointPolygon } from './domains/map.domain';
import { Field } from './domains/biblioteca.domain';

@Injectable({
  providedIn: 'root',
})
export class BibliotecaService {
  //delete this and create a new behaviour subject with info from service

  currentFields: Field[] = [];
  // coordinates?: google.maps.MVCArray<google.maps.LatLng>;
  // address: string = '';
  lastPosition?: PointPolygon;
  defaultPosition: PointPolygon = {
    lat: -37.3184252015075,
    lng: -59.13225350419924,
  };

  // locationRefuse: EventEmitter<boolean> = new EventEmitter<boolean>();

  // offlineMode: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {
    if (localStorage.getItem('position')) {
      this.lastPosition = JSON.parse(localStorage.getItem('position')!);
    }
  }

  setlastPosition(position: PointPolygon) {
    this.lastPosition = position;
    this.savePositionInStorage();
  }

  savePositionInStorage() {
    localStorage.setItem('position', JSON.stringify(this.lastPosition));
  }

  getLastPosition() {
    if (this.lastPosition) {
      return this.lastPosition;
    }
    return this.defaultPosition;
  }

  // setCoordinatesAndAddress(
  //   coordinates: google.maps.MVCArray<google.maps.LatLng>,
  //   address: string
  // ) {
  //   this.address = address;
  //   this.coordinates = coordinates;
  // }

  // getCoordinatesAndAddress() {
  //   return { coordinates: this.coordinates, address: this.address };
  // }


  // // //AGREGAR UN CAMPO
  // // async addCampo(campo: Field, location?: string, offline?: boolean) {
  // //   let addressArray = location;
  // //   if (!offline) {
  // //     const { Geocoder } = (await google.maps.importLibrary(
  // //       'geocoding'
  // //     )) as google.maps.GeocodingLibrary;
  // //     const geo = new Geocoder();
  // //     const locat = {
  // //       lat: campo.points![0].lat!,
  // //       lng: campo.points![0].lng!,
  // //     };
  // //     const data: google.maps.GeocoderResponse = await geo.geocode({
  // //       location: locat,
  // //     });
  // //     addressArray = data.results[data.results.length - 3].formatted_address;
  // //   }
  // //   const id = makeRandomId(9);
  // //   this.currentFields.push({
  // //     id: id,
  // //     idField: id,
  // //     name: campo.name,
  // //     location: addressArray!,
  // //     favs: false,
  // //     points: campo.points,
  // //     escenarios: [],
  // //     lotes: [],
  // //     calculations: {},
  // //   });
  // //   this.saveScenariosInStorage();
  // // }

  // // //BORRAR UN CAMPO
  // // deleteCampo(id: string) {
  // //   this.currentFields.splice(
  // //     this.currentFields.findIndex((campo) => campo.id === id),
  // //     1
  // //   );
  // //   localStorage.setItem('scenarios', JSON.stringify(this.currentFields));
  // // }

  // // //EDITAR UN CAMPO Y GUARDAR
  // // updateCampo(campo: Field) {
  // //   //this.currentFields.find((campo) => campo.id === id)!.points = campo.points;.
  // //   let fieldRef = this.currentFields.find((campox) => campox.id === campo.id);
  // //   if (fieldRef) {
  // //     fieldRef = campo;
  // //     this.saveScenariosInStorage();
  // //   }
  // // }

  // // //GUARDAR LOS CAMPOS EN EL LOCALSTORAGE
  // // saveScenariosInStorage() {
  // //   localStorage.setItem('scenarios', JSON.stringify(this.currentFields));
  // // }

  // // ESCENARIOS

  // // //AGREGAR UN ESCENARIO A UN CAMPO
  // // addEscenario(id: string, name: string) {
  // //   this.currentFields
  // //     .find((campo) => campo.id === id)!
  // //     .escenarios!.push({
  // //       id: makeRandomId(9),
  // //       name,
  // //       cultivos: 'Maíz, soja, trigo, etc.',
  // //       favs: false,
  // //       totalCarbono: 0,
  // //     });
  // //   this.saveScenariosInStorage();
  // // }

  // // //OBTENER UN ESCENARIO DE UN CAMPO ESPECIFICO
  // // getEscenario(campoID: string, id: string) {
  // //   const callback = this.getCampo(campoID)!.escenarios.find(
  // //     (escenario) => escenario.id === id
  // //   );
  // //   return callback;
  // // }

  // // //EDITAR UN ESCENARIO DE UN CAMPO Y GUARDAR
  // // updateEscenario(campoid: number, id: string, escenario: Scenario) {
  // //   let fieldActive = this.currentFields.find(
  // //     (campo) => campo.id?.toString() === campoid.toString()
  // //   );
  // //   fieldActive!.escenarios![
  // //     fieldActive!.escenarios!.findIndex(
  // //       (escenario: Scenario) => escenario.id === id
  // //     )
  // //   ] = escenario;
  // // //   this.saveScenariosInStorage();
  // // // }

  // // //BORRAR UN ESCENARIO DE UN CAMPO
  // // deleteScenario(escenarioID: string, campoID: string) {
  // //   let fieldActive = this.currentFields.find(
  // //     (campo: Field) => campo.id === campoID
  // //   );
  // //   fieldActive!.escenarios?.splice(
  // //     fieldActive!.escenarios?.findIndex(
  // //       (escenario: Scenario) => escenario.id === escenarioID
  // //     ),
  //     1
  //   );
  //   this.saveScenariosInStorage();
  // }

  // //COPIAR EL ESCENARIO DENTRO DE OTRO CAMPO
  // copyScenario(selectedField: Field, selectedRow: Scenario) {
  //   this.currentFields
  //     .find((campo: Field) => campo.id === selectedField.id)
  //     ?.escenarios?.push(selectedRow);
  //   this.saveScenariosInStorage();
  // }

  //RESETEAR DATOS DE BIBLIO
  // resetData() {
  //   this.currentFields = [];
  // }

  deleteStorage(){
    // this.currentFields = [];
    localStorage.clear()
    window.location.href = './';
  }

  //OBTENER UN CAMPO ESPECIFICO CON ID
  getCampo(id: string) {
    return this.currentFields.find((campo) => campo.id === id);
  }

  getCampos(){
    return this.currentFields;
  }
}
