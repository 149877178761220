import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataformComponent } from '../dataform/dataform.component';
import { FormsModule } from '@angular/forms';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { HttpClientModule } from '@angular/common/http';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputMaskModule } from 'primeng/inputmask';

@NgModule({
  declarations: [
    DataformComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ProgressSpinnerModule,
    HttpClientModule,
    DropdownModule,
    MultiSelectModule,
    InputMaskModule
  ],
  exports: [
    DataformComponent
  ]
})
export class DataformModule { }
