import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SupportModalDto, SupportTicketIn } from './domains/support.domain';

@Injectable({
  providedIn: 'root'
})

export class SupportService {

  public suppModal = new SupportModalDto();

  private supportModal: BehaviorSubject<SupportModalDto> = new BehaviorSubject<SupportModalDto>(this.suppModal);
  private supportConsultModal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private httpC: HttpClient) { }

  setOpenSupportModal(supportModal: SupportModalDto) {
    this.supportModal.next(supportModal);
  }
  observableOpenSupportModal() {
    return this.supportModal.asObservable();
  }

  openConsultModal(isOpen: boolean) {
    this.supportConsultModal.next(isOpen);
  }
  observableOpenConsultModal() {
    return this.supportConsultModal.asObservable();
  }

  cftSupportTicket() {
    return this.httpC.post<any>(`${environment.api}backoffice/support/cft`, {},);
  }

  supportForRoles(role: string){
    return this.httpC.post<any>(`${environment.api}backoffice/support/roles/${role}`, {});
  }

  sendFormToSupport(ticket: SupportTicketIn){
    return this.httpC.post<any>(`${environment.api}backoffice/support/landing-create-ticket`, ticket);
  }

}
