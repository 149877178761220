export class SupportModalDto {
  openModal?: boolean;
  role?: string;
  redirect?: string;
}

export class SupportTicketIn {
  name!: string;
  email!: string;
  message!: string;
  company?: string;
  type?: string;
  id?: number;
}
