import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DefaultResponse } from './domains/calculator.domain';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductividadService {

  constructor(private httpC: HttpClient) { }

  reloadFilters = new Subject<boolean>();

  getProductivityFilters(){
    return this.httpC.get<DefaultResponse<{id: number, name:string, urlReport:string}[]>>(environment.platformApi + 'cultivation/productivity/dashboard');
  }
}
