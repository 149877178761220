import { Component } from '@angular/core';
import { DialogModule } from 'primeng/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-patch-notes',
  standalone: true,
  templateUrl: './patch-notes.component.html',
  styleUrls: ['./patch-notes.component.scss'],
  imports: [
    DialogModule
  ]
})
export class PatchNotesComponent {
  currentVersion = environment.version
  pDialog = false;


}
