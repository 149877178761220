import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PremiumComponent } from './premium.component';
import { DialogModule } from 'primeng/dialog';

@NgModule({
  declarations: [
    PremiumComponent
  ],
  imports: [
    CommonModule,
    DialogModule
  ],
  exports: [
    PremiumComponent,

  ]
})
export class PremiumModule { }
