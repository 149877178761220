import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MigrandoComponent } from './migrando.component';
import { DialogModule } from 'primeng/dialog';
import { ProgressBarModule } from 'primeng/progressbar';



@NgModule({
  declarations: [
    MigrandoComponent
  ],
  imports: [
    CommonModule,
    DialogModule,
    ProgressBarModule
  ],
  exports: [
    MigrandoComponent
  ]
})
export class MigrandoModule { }
