import { Component, EventEmitter, Input, Output } from '@angular/core';
import { take } from 'rxjs';
import { CalculatorService } from 'src/app/services/calculator.service';
import { Person } from 'src/app/services/domains/calculator.domain';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-migrando',
  templateUrl: './migrando.component.html',
  styleUrls: ['./migrando.component.scss']
})
export class MigrandoComponent {

  @Input() migrando: boolean = false;
  @Output() migrandoChange = new EventEmitter<boolean>();

  @Input() migrationError: boolean = false;
  @Output() migrationErrorChange = new EventEmitter<boolean>();

  constructor(private readonly calcSrv: CalculatorService) {}

  onClose() {
    this.migrando = false;
    this.migrandoChange.emit(this.migrando);
  }
  onCloseError() {
    this.migrationError = false;
    this.migrationErrorChange.emit(this.migrationError);
  }

  round(number: number){
    return Math.floor(number);
  }

  total = 0;
  current = 0;
  migrado = false;
  retryMe() {
    return new Promise<Person>((resp) => {
      setTimeout(() => {
        this.calcSrv.getCalculatorMe().pipe(take(1)).subscribe({
          next: (res) => {
            this.calcSrv.makeClientProducerList(res.payload);
            if(res.payload.migrating) {
              this.total = res.payload.migrating;
              this.current = res.payload.migratedAmount;
              resp(res.payload);
              this.retryMe();
            } else {
              this.migrado = true;
              resp(res.payload);
            }
          },
          error: (err) => {
            if(err.status === 404) {
              this.migrationErrorChange.emit(true);
            } else if(err.status === 409) {
              this.migrationErrorChange.emit(true);
            } else if(err.status === 422) {
              // sigue el proceso..
              this.retryMe().then(r => resp(r));

            }
          }
        });
      }, environment.intervalMigratingRequest);
    })
  }
}
