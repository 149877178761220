<main class="dataform-container">
  <header class="df">
    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="20" viewBox="0 0 640 512"><path opacity="1" fill="#1E3050" d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM504 312V248H440c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V136c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H552v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z"/></svg>
    <h3>Gracias por sumarte!</h3>
    <h4>Por favor antes de empezar completá el siguiente formulario de registro para que podamos darte una experiencia más personalizada.</h4>
  </header>
  <section class="b1">
    <div class="form-group">
      <label>Actividad principal</label>
      <p-multiSelect styleClass="multiselect" [options]="activitiesOpt" [class.error]="mainActivity.error" [(ngModel)]="activities" optionLabel="name" optionValue="value"></p-multiSelect>
    </div>
    <div class="form-group">
      <label>Rol en la empresa</label>
      <p-dropdown class="drop" [options]="rolesOpt" [(ngModel)]="rol" optionLabel="name" optionValue="value" [filter]="true" filterBy="name" [showClear]="false" placeholder="Seleccionar rol en la empresa" appendTo="body">
        <ng-template let-rol pTemplate="item">
            <div style="display: flex;align-items: center;justify-content: flex-start;">
                <div>{{ rol.name }}</div>
            </div>
        </ng-template>
      </p-dropdown>
    </div>
    <div class="form-group">
      <label>CUIT <span class="hint">(Opcional)</span></label>
      <p-inputMask mask="99-99999999-9" [(ngModel)]="cuit.text" placeholder="20-99999999-5" [class.error]="cuit.error"></p-inputMask>

    </div>
    <div class="form-group">
      <label>Teléfono</label>
      <!-- <p-inputMask mask="(999) 999-9999" [(ngModel)]="phone.text" placeholder="(999) 999-9999" [class.error]="phone.error"></p-inputMask> -->
      <input type="number" placeholder="(999) 999-9999" [(ngModel)]="phone.text" [class.error]="phone.error"/>
    </div>
    <div class="form-group">
      <label>Dirección</label>
      <input type="text" #addresstext placeholder="Belgrano 435, Mar del Plata, Buenos Aires" [(ngModel)]="location.text" [class.error]="location.error"/>
    </div>
    <div class="form-group">
      <label>País</label>
      <p-dropdown class="drop" [options]="countries" [(ngModel)]="country.text" optionLabel="es_name" optionValue="es_name" [filter]="true" filterBy="es_name" [showClear]="false" placeholder="Seleccionar país" appendTo="body">
        <ng-template let-country pTemplate="item">
            <div style="display: flex;align-items: center;justify-content: flex-start;">
                <div>{{ country.es_name }}</div>
            </div>
        </ng-template>
      </p-dropdown>
    </div>
    <!-- <span class="empty-inputs" *ngIf="emptyInputs">Todos los campos son obligatorios.</span> -->
    <p-progressSpinner [style]="{width: '36px', height: '36px'}" *ngIf="loading" styleClass="custom-spinner" strokeWidth="7" fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
    <button class="primary" style="width: 100%;" (click)="save()" *ngIf="!loading">Guardar</button>
  </section>
</main>
