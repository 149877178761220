import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../auth.service';

export const simulatorGuard: CanActivateFn = (route, state) => {
    //Si no estas logueado, no verifica que tengas roles, intenta hacer la redireccion devolviendo true pero te lleva al SSO
  const authSrv = inject(AuthService);
  const ssoSrv = inject(AuthService);
  if(authSrv.isLogged()){
    let userRoles: string[] = [];
    const router = inject(Router);
    authSrv.setUserRoles();
    userRoles = authSrv.getUserRoles();
    if (!userRoles.includes('ROLE_SIMULATOR')) {
      router.navigateByUrl('/home');
      return false;
    }
    return true;
  } else {
    ssoSrv.goToSSO();
    return false;
  }

};
