import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultResponse } from 'src/app/services/domains/calculator.domain';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FillLoginService {

  constructor(private readonly httpC: HttpClient) { }

  fillLogin(data: UserData) {
    return this.httpC.post<DefaultResponse<{migrate:boolean}>>(`${environment.api}user/register`, data);
  }

}

export interface UserData {
  name?: string;
  lastName?: string;
  address: string;
  phone: string;
  country: string;
  mainActivity: string[];
  role: string[];
  cuitClient?: string;
}
