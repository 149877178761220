import { Component, EventEmitter, Output } from '@angular/core';
import { PremiumService } from './premium.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-premium',
  templateUrl: './premium.component.html',
  styleUrls: ['./premium.component.scss'],
  providers: [MessageService]
})
export class PremiumComponent {

  contacted = false;
  premiumModal = false;
  loading = false;

  constructor(private readonly premiumSrv: PremiumService,
              private readonly msgSrv: MessageService) {
    premiumSrv.onPremium.subscribe((tag: string) => {
      this.premiumModal = true;
    });
  }


  subscribe() {
    this.loading = true;
    this.premiumSrv.requestProSubscripiton().subscribe({
      next: () => {
        this.msgSrv.add({severity: 'success', summary: 'Solicitud aceptada', detail: 'Te contactaremos a la brevedad!'});
        this.loading = false;
        this.contacted = true;
      },
      error: (err) => {
        this.msgSrv.add({severity: 'error', summary: 'Error', detail: 'No se pudo realizar la solicitud: ' + err.error?.message});
        this.loading = false;
      }
    });
  }

  close() {
    this.premiumModal = false;
  }

}
